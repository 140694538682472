import $ from 'jquery'

function filterCat(e) {
    const list = $(this).parent().parent().parent().next().find('.masonry-grid');
    const header = list.find('li.article__header');
    const loader = $('.filter__loader');

    e.preventDefault();
    if(!$(this).hasClass('active')) {
        $(this).addClass('active')
        $('.js-filter').not(this).removeClass('active');
    }
    const category = $(this).attr('data-slug');
    const postType = $(this).attr('data-type');
    const data = {
        action : "filter_cat",
        cat : category,
        type : postType
    }

    return $.ajax({
        url: php_vars.ajax_url,
        data: data,
        dataType : 'json',
        type : 'POST',
        beforeSend : function(xhr){
            list.children().not(':first-child').remove();
            header.hide();
            loader.addClass('loading');
            $('.js-load-more').hide();
        },
        success : function( response ){
            if( response ) {
                header.show();
                list.append(response.content);
                loader.removeClass('loading');
                php_vars.current_page = 1;
                php_vars.posts = response.posts;
                php_vars.max_page = response.max_page;
                if ( response.max_page < 2 ) {
                    $('.js-load-more').hide();
                } else {
                    $('.js-load-more').show();
                }
            }
        } 

    });
}

function loadMore() {

    const list = $(this).parent().parent().find('.masonry-grid');
    const data = {
        'action': 'loadmore',
        'query': php_vars.posts,
        'page' : php_vars.current_page
    };
 
    $.ajax({
        url : php_vars.ajax_url,
        data : data,
        type : 'POST',
        beforeSend : function ( xhr ) {
            $('.js-load-more').text('Loading...');
        },
        success : function( posts ){
            if( posts ) {

                $('.js-load-more').text( 'Load more' );
                list.append( posts );
                php_vars.current_page++;

                if ( php_vars.current_page == php_vars.max_page ) 
                    $('.js-load-more').hide();

            } else {
                $('.js-load-more').hide();
            }
        }
    });
    return false;
}

function searchFilter() {
    const list = $(this).parent().next().find('.masonry-grid');
    const header = list.find('li.article__header');
    const loader = $('.filter__loader');
    console.log(list);
    console.log(header);
    const searchTerm = $(this).find("#search").val();
    const data = {
        action : "filter_search",
        search : searchTerm
    }

    return $.ajax({
        url: php_vars.ajax_url,
        data: data,
        dataType : 'json',
        type : 'POST',
        beforeSend : function(xhr){
            list.children().not(':first-child').remove();
            header.hide();
            loader.addClass('loading');
            $('.js-load-more').hide();
        },
        success : function( response ){
            console.log(response);
            if( response ) {
                header.show();
                list.append(response.content);
                loader.removeClass('loading');
                php_vars.current_page = 1;
                php_vars.posts = response.posts;
                php_vars.max_page = response.max_page;
                if ( response.max_page < 2 ) {
                    $('.js-load-more').hide();
                } else {
                    $('.js-load-more').show();
                }
            }
        } 

    });
}

function loadFilters() {
    const path = location.pathname.slice(0, -1).split('/').pop();
    if(/podcast-category/i.test(location.pathname) || /program-category/i.test(location.pathname)) {
        $('.js-filter').each(function() {
            const cat = $(this).attr('data-slug');
            if(path == cat) {
                $(this).addClass('active')
            } else {
                $(this).removeClass('active')
            }
        })
    }
}

export default function initFiltering(e) {
    $(document).on('click', '.js-filter', filterCat)
    $(document).on('click', '.js-load-more', loadMore )
    $(document).on('submit','.article__search--form', searchFilter )
    $(document).ready(loadFilters);
}